import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';

import { baConfig } from '../../config';
import { DataSourcesApi, DefaultApi } from '../../generated/data-core-svc';
import { store } from '../store';

// Extended API class that gives us access to the axios instance
class ExtendedDefaultApi extends DefaultApi {
  getAxiosInstance(): AxiosInstance {
    return this.axios;
  }
}

class DataCoreApiManager {
  private static defaultApiInstance: ExtendedDefaultApi;
  private static dataSourcesApiInstance: DataSourcesApi;

  /**
   * Normalize API URL to prevent double slashes
   * @param url The URL to normalize
   * @returns Normalized URL without trailing slash
   */
  private static normalizeApiUrl(url: string | undefined): string {
    if (!url) {
      return '';
    }
    // Remove trailing slash to prevent double slashes when combined with endpoint paths
    return url.endsWith('/') ? url.slice(0, -1) : url;
  }

  /**
   * Apply a request interceptor to fix trailing slashes in URLs
   * @param api The API instance to apply the interceptor to
   * THIS IS A WORKAROUND FOR THE FACT THAT THE API INSTANCE IS CREATED WITH A TRAILING SLASH
   */
  private static applyTrailingSlashFix(api: ExtendedDefaultApi): void {
    // Add an interceptor to the axios instance to fix trailing slashes
    const axiosInstance = api.getAxiosInstance();
    axiosInstance.interceptors.request.use((config) => {
      // Replace any instances of '/data-sources/' with '/data-sources'
      if (config.url) {
        config.url = config.url.replace('/data-sources/', '/data-sources');
      }
      return config;
    });
  }

  /**
   * Gets the DefaultApi instance
   * @returns DefaultApi instance
   */
  public static getInstance(): DefaultApi {
    if (!DataCoreApiManager.defaultApiInstance) {
      // Check if the org has the "import-v3" feature flag
      const hasImportV3Flag = store.getters.features && store.getters.features['import-v3'] === 'true';

      // Use api3Url if the feature flag is enabled, otherwise use apiUrl
      // (temp workaround till all orgs are migrated to data-core deploy in prod-a)
      const apiUrl = hasImportV3Flag ? baConfig.api3Url : baConfig.apiUrl;
      const normalizedUrl = this.normalizeApiUrl(apiUrl);

      DataCoreApiManager.defaultApiInstance = new ExtendedDefaultApi(undefined, normalizedUrl);

      // Apply the trailing slash fix to the API instance
      this.applyTrailingSlashFix(DataCoreApiManager.defaultApiInstance);
    }
    return DataCoreApiManager.defaultApiInstance;
  }

  /**
   * Gets the DataSourcesApi instance
   * @returns DataSourcesApi instance
   */
  public static getDataSourcesApi(): DataSourcesApi {
    if (!DataCoreApiManager.dataSourcesApiInstance) {
      // Check if the org has the "import-v3" feature flag
      const hasImportV3Flag = store.getters.features && store.getters.features['import-v3'] === 'true';

      // Use api3Url if the feature flag is enabled, otherwise use apiUrl
      // (temp workaround till all orgs are migrated to data-core deploy in prod-a)
      const apiUrl = hasImportV3Flag ? baConfig.api3Url : baConfig.apiUrl;
      const normalizedUrl = this.normalizeApiUrl(apiUrl);

      DataCoreApiManager.dataSourcesApiInstance = new DataSourcesApi(undefined, normalizedUrl);
    }
    return DataCoreApiManager.dataSourcesApiInstance;
  }
}

export default DataCoreApiManager;
